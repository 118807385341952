.navbar-brand img {
  max-height: 50px;
}
.success-shadow {
  -webkit-filter: drop-shadow( 0px 0px 5px rgba(40, 167, 69, .7));
  filter: drop-shadow( 0px 0px 5px rgba(40, 167, 69, .7));
}

.danger-shadow {
  -webkit-filter: drop-shadow( 0px 0px 5px rgba(220, 53, 69, .7));
  filter: drop-shadow( 0px 0px 5px rgba(220, 53, 69, .7));
}

aside.nav {
  width: 250px;
  display: inline-block;
}

.wrapper {
  width: calc(100% - 250px);
  display: inline-block;
}

footer {
  margin: 3rem;
  text-align: center;
}